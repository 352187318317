import { Alert, Snackbar } from '@mui/material'
import React from 'react'

export default function Snakbar({ data,setData }) {
    let { msg, show, type } = data
    let vertical = 'top'
    let horizontal = 'right'

    function closeSnackbar() {
        setData({ ...data, show: false });
    }



    // const [snackbar, showSnackbar] = useState({
    //     show: false,
    //     msg: "data added",
    //     type: "error",
    //   });



    return (
        <>
            <Snackbar
                sx={{ zIndex: "999999999999999999" }}
                anchorOrigin={{ vertical, horizontal }}
                autoHideDuration={3000}
                open={show}
                key={vertical + horizontal}
                onClose={closeSnackbar}
            >
                <Alert
                    severity={type}
                    sx={{ width: "100%" }}
                    onClose={closeSnackbar}
                >
                    {msg}
                </Alert>
            </Snackbar>
        </>
    )
}
