import React, { useEffect, useState } from 'react'
import Pagination from '../../Compunents/Pagination/Pagination'
import { MenuItem, TableHead, TableRow,Table, TableCell, TableBody } from '@mui/material'
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu'
import TableContainer from '../../Compunents/TableContainer/TableContainer'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { changeDateFormate, paginateData } from '../../Utils/Utils'
import { FiFilter } from 'react-icons/fi'
import Loader from '../../Compunents/Loader/Loader'
import { Modal, ModalBody } from 'reactstrap'
import { getCollectionData } from '../../Firebase/CloudFirestore/GetData'
import DateFilterModal from '../UserManagement/DateFilterModal'
import JobTypeModel from '../JobListing/JobTypeModel'
// import { Table } from 'reactstrap'

const FeeManagement = () => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(0);
  const rowsPerPage = 5
  const [data, setData] = useState([])
  const [allData, setAllData] = useState()

  const [noData, setNoData] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [openDateFilterModal, setOpenDateFilterModal] = useState(false)
  const [openJobTypeFilterModal, setOpenJobTypeFilterModal] = useState(false)

  const [statusChangeData, setStatusChangeData] = useState('')
  const [actionChangeData, setActionChangeData] = useState('')
  const [docId, setDocId] = useState('')
  const [openCommentModal, setopenCommentModal] = useState(false)

  useEffect(() => {
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
  }, [page, rowsPerPage])

  function filterByStatus(data) {
    let arr = [...allData]
    let newArr = arr?.filter(res => res?.status == data?.status)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenFilterModal(false)
  }
  function filterByJobType(data) {
    let arr = [...allData]
    let newArr = arr?.filter(res => res?.jobType == data?.jobType)
    console.log(data?.jobType);
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenJobTypeFilterModal(false)
  }

  function filterByDate(data) {
    let arr = [...allData]
    let newArr = arr?.filter(res => moment(res?.data?.create_time).format('YYYY-MM-DD') == data?.date)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenDateFilterModal(false)
  }

  function clearFilter() {
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }

  }
  function searchData(e) {
    let value = e?.toLowerCase()
    let arr = [...allData]
    if (e == '') {
      let slicedData = paginateData(page, rowsPerPage, allData)
      setData(slicedData)
    } else {
      let newArr = arr?.filter(res => res?.jobTitle?.toLowerCase()?.includes(value))
      let slicedData = paginateData(page, rowsPerPage, newArr)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    }

  }

  async function getFeesData(){
    setLoader();
    try{
        let res= await getCollectionData("payment");
        console.log(res,"fees");
        setAllData(res);
        let slicedData= paginateData(page, rowsPerPage, res);
        setData(slicedData)
    }
    catch (err){
      console.log(err);
    }
    finally{
      setLoader(false);
    }
  }
  useEffect(() => {
    getFeesData()
  }, [])
  return (
    <>
      <Modal size='md' centered isOpen={openCommentModal}>
        <ModalBody>
          <div className='fs-5 mb-2 fw-bold'>FeedBack</div>
          <textarea rows='5' className='form-control mt-3' placeholder='Enter your comment here' />
          <div className='d-flex justify-content-end gap-3 mt-3'>
            <button className='btn btn-sm border' onClick={() => {
              setopenCommentModal(false)
              setStatusChangeData('')
            }}>Cancel</button>
            <button className='btn btn-sm btn-primary'
            //  onClick={() => updateStatus()} 
             >Submit</button>
          </div>
        </ModalBody>
      </Modal>
      {/* <StatusFilterModal open={openFilterModal} setOpen={setOpenFilterModal} submit={filterByStatus} /> */}
      <DateFilterModal open={openDateFilterModal} setOpen={setOpenDateFilterModal} submit={filterByDate} />
      <JobTypeModel open={openJobTypeFilterModal} setOpen={setOpenJobTypeFilterModal} submit={filterByJobType} />
      {/* <Loader open={loader} /> */}
      <h2>Fee Management</h2>
      <div className='row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
          <input type='search' className='form-control w-100' placeholder='Search by Job Title' onChange={(e) => {
            if (e.target.value == ' ') {
              e.target.value = ''
            } else {
              searchData(e.target.value)
            }
          }} />
        </div>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>
          <DropDownMenu button={<button className='btn border'><FiFilter /> Filter by</button>}>
            {/* <MenuItem onClick={() => setOpenFilterModal(true)}>Status</MenuItem> */}
            <MenuItem onClick={() => setOpenDateFilterModal(true)}>Listing date</MenuItem>
            <MenuItem onClick={() => setOpenJobTypeFilterModal(true)}>Job Type</MenuItem>
            <hr />
            <MenuItem onClick={() => clearFilter()}>Clear all filters</MenuItem>
          </DropDownMenu>
        </div>
      </div>

      <div className='mt-4'>
        <TableContainer>
          <Table>
            {noData ? <caption className='text-center'>No Data Found</caption> : ''}
            <TableHead>
              <TableRow>
                <TableCell>Job Title</TableCell>
                <TableCell>Business</TableCell>
                <TableCell>Job Type</TableCell>
                <TableCell>Listing Fees</TableCell>
                <TableCell>Payment Date</TableCell>
                <TableCell>Listing Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((res, index) => {

                return (
                  <TableRow hover key={index} >
                    <TableCell className='pointer text-capitalize' onClick={() => navigate(`view/${res?.id}`)}>{res?.jobTitle}</TableCell>
                    <TableCell className='text-capitalize'>{res?.data?.payer?.payer_info?.business_name}</TableCell>

                    <TableCell>{res?.jobType}</TableCell>
                    <TableCell>{res?.data?.transactions.map(e=> e.amount.total)}</TableCell>
                    <TableCell >{moment(res?.data?.create_time).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                    <TableCell>{moment(res?.data?.create_time).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>

          </Table>
          <Pagination

            allData={allData}
            currentPage={page}
            paginate={(d) => {
              setPage(d);
            }}
            itemPerPage={rowsPerPage}
          />

        </TableContainer>
      </div>
    </>
  )
}

export default FeeManagement
