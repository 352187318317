import React, { useEffect } from 'react'

const FeedbackDetails = ({ activeTab, setLoader }) => {
  useEffect(() => {
    if (activeTab === '1') {
        // getallData()
    }
}, [activeTab])
  return (
    <div>
      xcvccxxcvxv
    </div>
  )
}

export default FeedbackDetails
