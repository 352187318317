import React, { useEffect, useState } from 'react'
import { MenuItem, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material'
import TableContainer from '../../Compunents/TableContainer/TableContainer'
import Loader from '../../Compunents/Loader/Loader'
import { getMatchingData } from '../../Firebase/CloudFirestore/GetData'
import { changeDateFormate, paginateData } from '../../Utils/Utils'
import Pagination from '../../Compunents/Pagination/Pagination'
import moment from 'moment'
import { FiFilter } from "react-icons/fi";
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu'
import StatusFilterModal from './StatusFilterModal'
import DateFilterModal from './DateFilterModal'
import { useNavigate } from 'react-router-dom'
import UserAvatar from '../../Compunents/User/UserAvatar'
import { Modal, ModalBody } from 'reactstrap'
import { updateDocument } from '../../Firebase/CloudFirestore/UpdateData'



export default function JobSeekers() {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(0);
  const rowsPerPage = 5
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [noData, setNoData] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [openDateFilterModal, setOpenDateFilterModal] = useState(false)

  const [statusChangeData, setStatusChangeData] = useState('')
  const [docId, setDocId] = useState('')
  const [openCommentModal, setopenCommentModal] = useState(false)


  async function getAllData() {
    try {
      let res = await getMatchingData('users', 'userType', '==', 'Job Seeker')
      if (res.length === 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
      let newRes = res?.map(item => {
        return {
          ...item,
          fullName: `${item?.firstName} ${item?.lastName}`
        }
      })
      setAllData(newRes)
      let slicedData = paginateData(page, rowsPerPage, newRes)
      setData(slicedData)

    } catch (err) {
      console.log(err)
    } finally {
      setLoader(false)
    }

  }

  useEffect(() => {
    getAllData()
  }, [])



  useEffect(() => {
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
  }, [page, rowsPerPage])

  function filterByStatus(data) {
    let arr = [...allData]
    let newArr = arr?.filter(res => res?.status == data?.status)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenFilterModal(false)
  }

  function filterByDate(data) {
    let arr = [...allData]
    let newArr = arr?.filter(res => moment(changeDateFormate(res?.createdAt)).format('YYYY-MM-DD') == data?.date)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenDateFilterModal(false)
  }

  function clearFilter() {
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }

  }

  function searchData(e) {
    let value = e?.toLowerCase()
    let arr = [...allData]
    if (e == '') {
      let slicedData = paginateData(page, rowsPerPage, allData)
      setData(slicedData)
    } else {
      let newArr = arr?.filter(res => res?.fullName?.toLowerCase()?.includes(value))
      let slicedData = paginateData(page, rowsPerPage, newArr)
      setData(slicedData)
    }

  }

  function changeStatus(e, id) {
    setDocId(id)
    setStatusChangeData(e)
    setopenCommentModal(true)

  }

  async function updateStatus() {
    setLoader(true)
    try {
      await updateDocument('users', docId, { status: statusChangeData })

    } catch (err) {
      console.log(err)
    } finally {
      await getAllData()
      setLoader(false)
      setopenCommentModal(false)
      setDocId('')
    }
  }

  function statusChange(key) {
    switch (key) {
      case 'active':
        return 'text-success'
        break;
      case 'inactive':
        return 'text-danger'
        break;
      case 'suspended':
        return 'text-warning'
        break;

      default:
        break;
    }
  }


  return (
    <>
      <Modal size='md' centered isOpen={openCommentModal}>
        <ModalBody>
          <div className='fs-5 mb-2 fw-bold'>Enter Comment</div>
          <textarea rows='5' className='form-control mt-3' placeholder='Enter your comment here' />
          <div className='d-flex justify-content-end gap-3 mt-3'>
            <button className='btn btn-sm border' onClick={() => {
              setopenCommentModal(false)
              setStatusChangeData('')
            }}>Cancel</button>
            <button className='btn btn-sm btn-primary' onClick={() => updateStatus()}>Submit</button>
          </div>
        </ModalBody>
      </Modal>
      <StatusFilterModal open={openFilterModal} setOpen={setOpenFilterModal} submit={filterByStatus} />
      <DateFilterModal open={openDateFilterModal} setOpen={setOpenDateFilterModal} submit={filterByDate} />
      <Loader open={loader} />
      <h2>Job Seekers</h2>
      <div className='row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
          <input type='search' className='form-control w-100' placeholder='Search user by name' onChange={(e) => {
            if (e.target.value == ' ') {
              e.target.value = ''
            } else {
              searchData(e.target.value)
            }
          }} />
        </div>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>
          <DropDownMenu button={<button className='btn border'><FiFilter /> Filter by</button>}>
            <MenuItem onClick={() => setOpenFilterModal(true)}>Status</MenuItem>
            <MenuItem onClick={() => setOpenDateFilterModal(true)}>Registration date</MenuItem>
            <hr />
            <MenuItem onClick={() => clearFilter()}>Clear all filters</MenuItem>
          </DropDownMenu>
        </div>
      </div>

      <div className='mt-4'>
        <TableContainer>
          <Table>
            {noData ? <caption className='text-center'>No Data Available</caption> : ''}
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '2%' }}></TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Nationality</TableCell>
                <TableCell>Registration Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((res, index) => {
                console.log(data)
                return (
                  <TableRow hover key={index}>
                    <TableCell><UserAvatar name={res?.firstName} img={res?.profileImage} /></TableCell>
                    <TableCell className='pointer text-capitalize' onClick={() => navigate(`view/${res?.
                      userId}`)}>{res?.firstName} {res?.lastName}</TableCell>
                    <TableCell className='text-capitalize'>{res?.gender}</TableCell>
                    <TableCell>{res?.phoneNumber}</TableCell>
                    <TableCell>{res?.email}</TableCell>
                    <TableCell className='text-capitalize'>
                      <select className={`form-select bg-transparent f-14 px-1 ${statusChange(res?.status)}`} value={res?.status} onChange={(e) => changeStatus(e.target.value, res?.id)}>
                        <option className='f-14 text-success' value='active'>Active</option>
                        <option className='f-14 text-danger' value='inactive'>Inactive</option>
                        <option className='f-14 text-warning' value='suspended'>Suspendend</option>
                      </select>
                    </TableCell>
                    <TableCell>{res?.nationality}</TableCell>
                    <TableCell>{moment(changeDateFormate(res?.createdAt)).format('DD MMM YYYY')}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>

          </Table>
          <Pagination
            allData={allData}
            currentPage={page}
            paginate={(d) => {
              setPage(d);
            }}
            itemPerPage={rowsPerPage}
          />

        </TableContainer>
      </div>
    </>
  )
}
