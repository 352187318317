import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { IoClose } from "react-icons/io5";

export default function AddDocumentModal({ open, setOpen, setSelectStatus, setFile, uploadDocuments, selectStatus, file }) {

    function toggle() {
        setOpen(!open)
        setFile(null)
        setSelectStatus('')
    }


    return (
        <>
            <Modal size='md' isOpen={open} centered scrollable>
                <ModalBody>
                    <form onSubmit={uploadDocuments}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='fs-5 fw-bold'>Upload Document</div>
                            <IoClose className='fs-5 pointer' onClick={toggle} />
                        </div>
                        <div className='mt-4'>
                            <label className='form-label mb-1'>Select File</label>
                            <input type='file' className='form-control' required  accept="application/pdf" onChange={(e) => {
                                setFile(e.target.files[0])
                            }} />
                        </div>
                        <div className='mt-3'>
                            <label className='form-label mb-1'>Select Status</label>
                            <select className='form-select' required value={selectStatus} onChange={(e) => setSelectStatus(e.target.value)}>
                                <option value=''>Select Status</option>
                                <option value='pending'>Pending</option>
                                <option value='verified'>Verified</option>
                                <option value='rejected'>Rejected</option>
                            </select>
                        </div>
                        <div className='mt-4 d-flex justify-content-end gap-3'>
                            <button className='btn btn-sm border' type='button' onClick={toggle}>Cancel</button>
                            <button className='btn btn-sm btn-primary' type='submit'>Submit</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </>
    )
}
