import React from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { IoMdClose } from "react-icons/io";

export default function CustomDrawer({ children, open, toggle, header }) {

    return (
        <>
            <Drawer
                open={open}
                onClose={toggle}
                direction='right'
                className='p-3 drawer'
                zIndex={1200}
            >
                <div className="d-flex justify-content-between align-items-center">
                    <div className="f-22 fw-600">{header}</div>
                    <IoMdClose className="fs-18 pointer" onClick={toggle} />
                </div>
                <div className='w-100'>
                    {children}
                </div>
            </Drawer>
        </>
    )
}