import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { IoClose } from "react-icons/io5";
import { useForm } from 'react-hook-form';

export default function StatusChangeModal({ open, setOpen, submit, status, setStatus }) {
    const { register, handleSubmit, formState: { errors } } = useForm();

    function toggle() {
        setOpen(!open)
        setStatus('')
    }


    return (
        <Modal size='sm' isOpen={open} centered >
            <ModalBody>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='fw-600'>Select status</div>
                    <IoClose className='fs-5 pointer' onClick={toggle} />
                </div>
                {open?<div className='pt-3'>
                    <form onSubmit={handleSubmit(submit)}>
                        <div className='d-flex flex-column gap-3'>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" required value='pending' onChange={(e)=>setStatus(e.target.value)} checked={status === 'pending'} id="radio1" />
                                <label class="form-check-label" for="radio1">
                                    Pending
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" required value='verified' onChange={(e)=>setStatus(e.target.value)} checked={status === 'verified'} id="radio2" />
                                <label class="form-check-label" for="radio2">
                                    Verified
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" required value='rejected' onChange={(e)=>setStatus(e.target.value)} checked={status === 'rejected'} id="radio3" />
                                <label class="form-check-label" for="radio3">
                                    Rejected
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-primary' type='submit'>Submit</button>
                        </div>
                    </form>
                </div>:''}
            </ModalBody>
        </Modal>
    )
}
