import React, { useState } from 'react'
import style from './style.module.css'
import { useNavigate } from 'react-router-dom'
import { emailPasswordLogin } from '../../Firebase/FirebaseAuth/UserLogin'
import Snakbar from '../../Compunents/Snackbar/Snakbar'
import { Spinner } from 'reactstrap'
import { getDocumentData } from '../../Firebase/CloudFirestore/GetData'
// import { emailPasswordLogin } from '.'

export default function Login() {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [loader, setLoader] = useState(false)
  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  })
  const [snackbar, showSnackbar] = useState({
    show: false,
    msg: "data added",
    type: "error",
  });



  async function login(e) {
    e.preventDefault()
    setLoader(true)
    try {
      let res = await emailPasswordLogin(credentials)
      console.log(res?.user?.uid)
      if (res?.user?.uid) {
        localStorage.setItem('userId', res?.user?.uid)
        let res2 = await getDocumentData('users', res?.user?.uid)
        if (res2?.userType !== 'admin') {
          showSnackbar({ show: true, msg: 'Wroung email id or password', type: 'error' })
          setLoader(false)
          return
        }
        navigate('/userManagement/business')
      } else {
        showSnackbar({ show: true, msg: 'Wroung email id or password', type: 'error' })

      }

    } catch (err) {
      console.log(err)
      showSnackbar({ show: true, msg: 'Something went wroung please try again', type: 'error' })

    } finally {
      setLoader(false)

    }
  }

  function handleChange(e) {
    let { name, value } = e.target

    if (e.target.value === ' ') {
      e.target.value = ''
    } else {
      setCredentials(pre => {
        return {
          ...pre,
          [name]: value
        }
      })
    }
  }



  return (
    <>
      <Snakbar data={snackbar} setData={showSnackbar} />
      <div className={style.mainContainer}>
        <div className={style.left}>
          <img src='/images/LinkEase.png' alt='' className='w-50' />
        </div>
        <div className={style.right}>
          <div className={style.centerContent}>
            <form onSubmit={login}>
              <div className='text-center fs-3 fw-bold pb-5'>
                <img src='/images/LinkEase.png' alt='' className='w-50' />
              </div>
              <div className='fw-bold fs-1 text-center text-black'>Welcome Back</div>
              <div className='text-center text-secondary'>Enter Your Email and Password To Access Your Account</div>
              <div className='mt-4'>
                <label className='form-label fw-600 f-14'>Email</label>
                <input type='email' required name='email' value={credentials.email} onChange={handleChange} className='form-control py-2 f-14' placeholder='Enter Your Email' />
              </div>
              <div className='mt-4'>
                <label className='form-label fw-600 f-14'>Password</label>
                <div className={style.password_container}>
                  <input
                    type={show ? 'password' : 'text'}
                    required
                    name='password'
                    value={credentials.password}
                    onChange={handleChange}
                    className='form-control py-2 f-14'
                    placeholder='Enter Your Password'
                  />
                  <div className={style.icon} onClick={() => setShow(!show)}>{show ? <i className={`bi bi-eye-slash fs-5`}></i> : <i className={`bi bi-eye fs-5`}></i>}</div>
                </div>
              </div>
              <div className='text-end label pointer mt-2'>Forgot Password?</div>
              <button type='submit' className={`btn btn-primary w-100 py-2 mt-4 `} disabled={loader}>{loader ? <Spinner size='sm' color="light">
                Loading...
              </Spinner> : 'Sign In'}</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
