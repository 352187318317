import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getMatchingData } from '../../../Firebase/CloudFirestore/GetData'
import moment from 'moment'
import { changeDateFormate } from '../../../Utils/Utils'

export default function CertificatDetails({ activeTab, setLoader }) {
    let { id } = useParams()
    const [allCertifications, setAllCertifications] = useState([])


    async function getProfetionalData() {
        setLoader(true)
        try {
            let [res] = await getMatchingData('professionalDetails', 'userId', '==', id)
            if (res !== undefined) {
                console.log(res?.certifications)
                setAllCertifications(res?.certifications)
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (activeTab === '3') {
            getProfetionalData()
        }
    }, [activeTab])
  return (
    <>
    <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='fs-5 fw-600 text-secondary'>Certifications</div>
                    {/* <button className='btn btn-sm btn-primary' onClick={() => setOpenAddModal(true)}>Add Document</button> */}
                </div>
                <div className={` mt-4`}>
                    {allCertifications?.map((res, index) => (
                        <>
                            <div key={index} className='border rounded p-3 mt-3'>
                                <div className='d-flex flex-md-row flex-sm-row flex-column justify-content-md-between justify-content-sm-between justify-content-start'>
                                    <div className='fw-bold text-secondary text-capitalize'>{res?.title}</div>
                                    <div className='f-14 text-gray'>{moment(changeDateFormate(res?.startDate))?.format('MMM YYYY')} - {moment(changeDateFormate(res?.endDate))?.format('MMM YYYY')}</div>
                                </div>
                                <div className='f-14 text-gray mt-2'>Certificate ID - {res?.certificateId}</div>
                            </div>
                        </>
                    ))}
        
                </div>
            </div>
    </>
  )
}
