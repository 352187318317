import { MenuItem, TableBody, TableCell, TableHead, TableRow,Table } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import TableContainer from '../../Compunents/TableContainer/TableContainer';
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu';
import { FiFilter } from 'react-icons/fi';
import Pagination from '../../Compunents/Pagination/Pagination';
import CustomDrawer from '../../Compunents/CustomDrawer/CustomDrawer';
import { changeDateFormate, paginateData } from '../../Utils/Utils';
import moment from 'moment';
import DateFilterModal from '../UserManagement/DateFilterModal';
import { getCollectionData } from '../../Firebase/CloudFirestore/GetData';
import { updateDocument } from '../../Firebase/CloudFirestore/UpdateData';
import Loader from '../../Compunents/Loader/Loader';

export default function SystemManagement() {
 
  const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(0);
  const rowsPerPage = 5
 
  const [allData, setAllData] = useState();
  const [data, setData] = useState([]);

  const [noData, setNoData] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [openDateFilterModal, setOpenDateFilterModal] = useState(false)
  const [openJobTypeFilterModal, setOpenJobTypeFilterModal] = useState(false)

  const [statusChangeData, setStatusChangeData] = useState('')
  const [docId, setDocId] = useState('')
  const [openCommentModal, setopenCommentModal] = useState(false)
  const [rowmodal, setrowModal] = useState({ status: false, fullData: {} });
  const [userPermission, setUserPermission] = useState(['View Payment']);
  const rowtoggle = (data) => {
    if (userPermission.includes("View Payment")) {
      if (true) {
        setrowModal({ status: !rowmodal.status, fullData: data });
      }
    }
  };

  async function getListingData() {
    setLoader(true);
    try {
      let res = await getCollectionData('system');
      // console.log(res);
      setAllData(res); 
      let slicedData = paginateData(page, rowsPerPage, res); 
      setData(slicedData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  }
  function changeStatus(e, id) {
    setDocId(id)
    setStatusChangeData(e)
    setopenCommentModal(true)

  }

  async function updateStatus() {
    setLoader(true)
    try {
      await updateDocument('system', docId, { status: statusChangeData})

    } catch (err) {
      console.log(err)
    } finally {
      await getListingData()
      setLoader(false)
      setopenCommentModal(false)
      setDocId('')
    }
  }

  // function filterByJobType(data) {
  //   let arr = [...allData]
  //   let newArr = arr?.filter(res => res?.jobType == data?.jobType)
  //   // console.log(data?.jobType);
  //   let slicedData = paginateData(page, rowsPerPage, newArr)
  //   setData(slicedData)
  //   if (slicedData.length == 0) {
  //     setNoData(true)
  //   } else {
  //     setNoData(false)
  //   }
  //   setOpenJobTypeFilterModal(false)
  // }

  function filterByDate(data) {
    let arr = [...allData]
    let newArr = arr?.filter(res => moment(changeDateFormate(res?.date)).format('YYYY-MM-DD') == data?.date)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenDateFilterModal(false)
  }
  function searchData(e) {
    let value = e?.toLowerCase()
    let arr = [...allData]
    if (e == '') {
      let slicedData = paginateData(page, rowsPerPage, allData)
      setData(slicedData)
    } else {
      let newArr = arr?.filter(res => res?.name?.toLowerCase()?.includes(value))
      let slicedData = paginateData(page, rowsPerPage, newArr)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    }

  }

  function clearFilter() {
    setLoader(true)
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setLoader(false)

  }
  useEffect(()=>{
    getListingData()
  },[])


  return (
    <>
      <Modal size='md' centered isOpen={openCommentModal}>
        <ModalBody>
          <div className='fs-5 mb-2 fw-bold'>Enter Comment</div>
          <textarea rows='5' className='form-control mt-3' placeholder='Enter your comment here' />
          <div className='d-flex justify-content-end gap-3 mt-3'>
            <button className='btn btn-sm border' onClick={() => {
              setopenCommentModal(false)
              setStatusChangeData('')
            }}>Cancel</button>
            <button className='btn btn-sm btn-primary' 
            onClick={() => updateStatus()}
             >Submit</button>
          </div>
        </ModalBody>
      </Modal>
 
       <DateFilterModal open={openDateFilterModal} setOpen={setOpenDateFilterModal} submit={filterByDate} />
  
      <Loader open={loader} />
      <h2>System Management</h2>
      <div className='row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
        <input type='search' className='form-control w-100' placeholder='Search'onChange={(e) => {
            if (e.target.value == ' ') {
              e.target.value = ''
            } else {
              searchData(e.target.value)
            }
          }} />
        </div>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>
          <DropDownMenu button={<button className='btn border'><FiFilter /> Filter by</button>}>
           
            <MenuItem onClick={() => setOpenDateFilterModal(true)}>Date</MenuItem>
          
            <hr />
            <MenuItem 
            onClick={() => clearFilter()}
            >Clear all filters</MenuItem>
          </DropDownMenu>
        </div>
      </div>

      <div className='mt-4'>
        <TableContainer>
          <Table>
            {noData ? <caption className='text-center'>No Data Found</caption> : ''}
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Work Experience</TableCell>
                <TableCell>Skill Level</TableCell>
                <TableCell>Date</TableCell>
                
                

              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((res, index) => { 
              
              return (
                <TableRow hover key={index} >
                 <TableCell
                    className={`text-nowrap text-capitalize ${userPermission.includes("View Payment") ? "pointer" : ""}`}
                    onClick={() => rowtoggle(res)}
                  >
                    {res.name}
                  </TableCell>
                
                  <TableCell>{res.workExperience}</TableCell>
               
                  <TableCell>{res.skillLevel}</TableCell>
                  <TableCell>{moment(changeDateFormate(res?.date)).format('DD MMM YYYY')}</TableCell>
                  </TableRow>
                 ) 
              })} 
            </TableBody>

          </Table>
          <Pagination

            allData={allData}
            currentPage={page}
            paginate={(d) => {
              setPage(d);
            }}
            itemPerPage={rowsPerPage}
          />

        </TableContainer>
        <CustomDrawer open={rowmodal.status} toggle={() => rowtoggle({})} header={'System Details'}>
        <div className="mt-3">
          
          <hr />
          <div className="row">
            <div className="col-md-6 mt-3">
              <label className="f-16">Name</label>
              <input
                className='form-control'
                disabled
                type="text"
                value={rowmodal.fullData.name || ''}
              />
            </div>
            <div className="col-md-6 mt-3">
              <label className="f-16">Work Experience</label>
              <input
                className='form-control'
                disabled
                type="text"
                value={rowmodal.fullData.workExperience || ''}
              />
            </div>
            <div className="col-md-6 mt-3">
              <label className="f-16">Skill Level</label>
              <input
                className='form-control'
                disabled
                type="text"
                value={rowmodal.fullData.skillLevel || ''}
              />
            </div>
            <div className="col-md-6 mt-3">
              <label className="f-16">Date</label>
              <input
                className='form-control'
                disabled
                type="text"
                value={moment(changeDateFormate(rowmodal?.fullData?.date)).format('DD MMM YYYY')}
              />
            </div>
            
          </div>
        </div>
      </CustomDrawer>
      </div>
    </>
  )
}