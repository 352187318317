import './App.css';
import { Route, Routes } from 'react-router-dom';
import LoginAuth from './Auth/LoginAuth';
import Dashboard from './Pages/Dashboard/Dashboard';
import JobListing from './Pages/JobListing/JobListing';
import Payments from './Pages/Payments/Payments';
import SystemManagement from './Pages/SystemManagement/SystemManagement';
import Login from './Pages/Authentication/Login';
import Bussinesess from './Pages/UserManagement/Bussinesess';
import JobSeekers from './Pages/UserManagement/JobSeekers';
import ViewBusinessData from './Pages/UserManagement/BusinesessFiles/ViewBusinessData';
import ViewJobSeekerData from './Pages/UserManagement/JobSeekersFiles/ViewJobSeekerData';
import ViewListing from './Pages/JobListing/ViewListing';
import Notifications from './Pages/Notification/Notifications';
import FeeManagement from './Pages/FeeManagement/FeeManagement';
import ViewPayment from './Pages/Payments/ViewPayment';
import JobTitleListing from './Pages/JobTitleListing/JobTitleListing';
import CreateJobTitle from './Pages/JobTitleListing/CreateJobTitle';

function App() {
  return (
    <>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<LoginAuth />}>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/userManagement/business' element={<Bussinesess />} />
          <Route path='/userManagement/business/view/:id' element={<ViewBusinessData />} />
          <Route path='/userManagement/jobSeekers' element={<JobSeekers />} />
          <Route path='/userManagement/jobSeekers/view/:id' element={<ViewJobSeekerData />} />
          <Route path='/jobListing' element={<JobListing />} />
          <Route path='/jobTitles' element={<JobTitleListing />} />
          <Route path='/jobTitles/create' element={<CreateJobTitle />} />
          <Route path='/jobListing/view/:id' element={<ViewListing />} />
          <Route path='/payments' element={<Payments />} />
          <Route path='/payments/view/:id' element={<ViewPayment />} />
          <Route path='/feeManagement' element={<FeeManagement />} />
          <Route path='/systemManagement' element={<SystemManagement />} />
          <Route path='/notifications' element={<Notifications />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
