import { Menu, MenuList } from '@mui/material';
import React, { useState } from 'react'

export default function DropDownMenu({ button, children }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      > {button}</div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuList onClick={handleClose}>{children}</MenuList>
      </Menu>
    </>
  )
}
