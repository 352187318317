import React, { useEffect, useState } from 'react'
import style from './style.module.css'
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { useNavigate } from 'react-router-dom';
// import { MdSpaceDashboard } from "react-icons/md";
import { MdOutlineFormatListBulleted } from "react-icons/md";
import { MdPayments } from "react-icons/md";
import { FaUsersCog } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import User from '../User/User';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosBusiness } from "react-icons/io";
import { HiUserGroup } from "react-icons/hi2";


const drawerWidth = 260;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
}));

export default function Sidebar({ children }) {
    const matches = useMediaQuery('(min-width:1199px)');
    const [open, setOpen] = useState(true)
    const [toggle1, setToggle1] = useState(false)
    const navigate = useNavigate()
    let location = window.location.href

    function activeRoute(res) {
        if (location.includes(res)) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (matches) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [matches])

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <AppBar sx={{ background: 'white' }} position="fixed" open={!matches ? false : open}>
                    <Toolbar className='d-flex justify-content-between align-items-center'>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setOpen(!open)}
                            edge="start"
                            sx={{ mr: 2, mt: -1 }}
                        >
                            <i className="bi bi-list text-black fw-700"></i>
                        </IconButton>
                        <div className='pointer text-black pe-md-4 pe-sm-2 pe-0 mt-1' style={{ marginTop: '-10px' }}>
                            <User />
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant={!matches ? 'temporary' : "persistent"}
                    open={open} onClose={() => setOpen(!open)}>
                    <DrawerHeader>
                        <img src='/images/LinkEase.png' alt='' className='w-75' />
                    </DrawerHeader>
                    <Divider />
                    <List>
                        {/* <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('dashboard')?'activeTab':''} rounded`} onClick={()=>navigate('/dashboard')}>
                                <ListItemIcon>
                                    <MdSpaceDashboard className={`fs-5 ${activeRoute('dashboard')?'':'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={'Dashboard'} />
                            </ListItemButton>
                        </ListItem> */}
                        <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('userManagement') ? 'activeTab' : ''} rounded`} onClick={() => setToggle1(!toggle1)}>
                                <ListItemIcon>
                                    <FaUsersCog className={`fs-5 ${activeRoute('userManagement') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={<div className='w-100 d-flex align-items-center f-15'>User Management {toggle1 ? <IoIosArrowUp className='ms-2' /> : <IoIosArrowDown className='ms-2' />}</div>} />
                            </ListItemButton>
                        </ListItem>
                        <div className={`px-3 ps-5 ${toggle1?'pt-2':''}`} style={{ height: toggle1 ? '110px' : '0px', overflow: 'hidden', transition: 'height 0.3s' }}>
                        <List className=''>
                            <ListItemButton className={`text-black ${activeRoute('business') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/userManagement/business')}>
                                <ListItemIcon>
                                    <IoIosBusiness className={`fs-5 ${activeRoute('business') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={<div className='f-15'>Business</div>} />
                            </ListItemButton>
                            <ListItemButton className={`text-black ${activeRoute('jobSeeker') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/userManagement/jobSeekers')}>
                                <ListItemIcon>
                                    <HiUserGroup className={`fs-5 ${activeRoute('jobSeeker') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={<div className='f-15'>Job Seeker</div>} />
                            </ListItemButton>
                        </List>
                        </div>
                        <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('jobTitles') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/jobTitles')}>
                                <ListItemIcon>
                                    <MdOutlineFormatListBulleted className={`fs-5 ${activeRoute('jobTitles') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={<div className='f-15'>Job Titles</div>} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('jobListing') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/jobListing')}>
                                <ListItemIcon>
                                    <MdOutlineFormatListBulleted className={`fs-5 ${activeRoute('jobListing') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={<div className='f-15'>Job Listing</div>} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('feeManagement') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/feeManagement')}>
                                <ListItemIcon>
                                <MdPayments className={`fs-5 ${activeRoute('feeManagement') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={<div className='f-15'>Fee Management</div>} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('payments') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/payments')}>
                                <ListItemIcon>
                                    <MdPayments className={`fs-5 ${activeRoute('payments') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={<div className='f-15'>Payments</div>} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('systemManagement') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/systemManagement')}>
                                <ListItemIcon>
                                    <FiSettings className={`fs-5 ${activeRoute('systemManagement') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={<div className='f-15'>System Management</div>} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('notifications') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/notifications')}>
                                <ListItemIcon>
                                    <FaUsersCog className={`fs-5 ${activeRoute('notifications') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={'Notifications'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Drawer>
                <Main open={!matches ? true : open}>
                    <div className={style.mainContainer}>
                        <div className={style.topbar}></div>
                        <div className={`${style.content} p-md-4 p-sm-3 p-2`}>
                            {children}
                        </div>
                    </div>
                </Main>
            </Box>
        </>
    )
}
