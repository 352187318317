import React, { useEffect, useState } from 'react'
import { IoArrowBack } from "react-icons/io5";
import { useNavigate, useParams } from 'react-router-dom';
// import Loader from '../../../Compunents/Loader/Loader';
import style from './style.module.css'
import { FaCamera } from "react-icons/fa";
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { IoIosArrowForward } from "react-icons/io";
// import { deleteImage, uploadFile } from '../../../Firebase/CloudStorage/UploadImages';
// import { updateDocument } from '../../../Firebase/CloudFirestore/UpdateData';
// import Snakbar from '../../../Compunents/Snackbar/Snakbar';
// import { getDocumentData } from '../../../Firebase/CloudFirestore/GetData';
import { useMediaQuery } from '@mui/material';
import ProfileEdit from '../UserManagement/JobSeekersFiles/ProfileEdit';
import EducationDetails from '../UserManagement/JobSeekersFiles/EducationDetails';
import ExperienceDetails from '../UserManagement/JobSeekersFiles/ExperienceDetails';
import CertificatDetails from '../UserManagement/JobSeekersFiles/CertificatDetails';
import { deleteImage, uploadFile } from '../../Firebase/CloudStorage/UploadImages';
import Loader from '../../Compunents/Loader/Loader';
import Snakbar from '../../Compunents/Snackbar/Snakbar';
import JobDetailsView from './JobDetailsView';
import Feedback from './FeedbackDetails';
import { getDocumentData } from '../../Firebase/CloudFirestore/GetData';

export default function ViewListing() {
    let { id } = useParams()
    console.log(id)
    const navigate = useNavigate()
    const matches = useMediaQuery('(min-width:768px)');
    const [imgUrl, setImgUrl] = useState('')
    const [loader, setLoader] = useState(false)
    const [activeTab, toggleTab] = useState('0')
    const [showData, setShowData] = useState({
        name: '',
        email: '',
        gender: ''
    })
    const [snackbar, showSnackbar] = useState({
        show: false,
        msg: "data added",
        type: "error",
    });







    async function getUserData() {
        setLoader(true)

        try {
            let res = await getDocumentData('users', id)
            console.log(res)
            // setShowData(res)

        } catch (err) {
            console.log(err)
        }
        setLoader(false)
    }


    useEffect(() => {
        getUserData()
    },[])

    return (
        <>
            <Loader open={loader} />
            <Snakbar data={snackbar} setData={showSnackbar} />
            <button className='btn ps-0 d-flex align-items-center' onClick={() => navigate('/userManagement/jobSeekers')}><IoArrowBack className='me-1' /> Back</button>
            <div className='mt-4'>
                <div className='d-flex align-items-center'>
                    <div className='p-relative'>
                        {/* <div className={`${style.profileContainer}`}>
                            <img src="/images/defaultProfile.jpg" alt="" />
                           
                        </div> */}

                    </div>
                    <div className='d-flex flex-column ms-md-4 ms-sm-3 ms-3'>
                        <div className='fs-md-3 fs-sm-4 fs-5 fw-bold pt-3 text-secondary text-capitalize'>{showData?.name}</div>
                        <div className='text-gray ms-1'>{showData?.email}</div>
                    </div>
                </div>
            </div>
            <div className={`mt-4 ${style.tabContainer}`}>
                <div className={`${style.left}`}>
                    <Nav vertical={matches} className={`${!matches ? 'border-bottom flex flex-nowrap' : ''}`}>
                        <NavItem>
                            <NavLink
                                className={`text-secondary fw-bold ${activeTab == "0" ? !matches ? 'activeTab2' : 'activeTab text-white ' : ""} ${!matches ? 'px-2' : 'rounded ps-4 mt-2 py-2'} pointer`}
                                onClick={() => toggleTab("0")}>
                                <div className='d-flex justify-content-between align-items-center'><div>job Details</div> <IoIosArrowForward className='fw-600 d-md-block d-sm-none d-none' /></div>
                            </NavLink>
                        </NavItem>
                       

                    </Nav>

                </div>
                <div className={`${style.right}`}>
                    <TabContent className="px-md-3 px-sm-2 px-1 pt-md-4 pt-sm-4 pt-3" activeTab={activeTab}>
                        <TabPane tabId="0">
                            <JobDetailsView activeTab={activeTab} setLoader={setLoader} />
                        </TabPane>
                        <TabPane tabId="1">
                            <Feedback activeTab={activeTab} setLoader={setLoader} />
                        </TabPane>

                    </TabContent>
                </div>
            </div>
        </>
    )
}

