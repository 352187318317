import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { IoClose } from "react-icons/io5";


export default function ViewFile({ open, setOpen, data, setViewData }) {

    function toggle() {
        setOpen(!open)
        setViewData({})
    }

    return (
        <>
            <Modal size='xl' isOpen={open} centered scrollable toggle={toggle} className=''>
                <ModalBody className='p-0' style={{ background: '#323639' }}>
                    <div className='d-flex justify-content-end'>
                        <IoClose className='fs-5 pointer text-white me-4 mt-3' title='Close' onClick={toggle} />
                    </div>
                    <iframe
                        style={{ width: '100%', height: '80vh' }}
                        title='Document view'
                        src={data?.fileUrl}
                    >
                    </iframe>
                </ModalBody>
            </Modal>
        </>
    )
}
