import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { getCollectionData } from '../../Firebase/CloudFirestore/GetData'
import { changeDateFormate } from '../../Utils/Utils'

const PaymentDetailsView = ({ activeTab, setLoader }) => {
    let { id } = useParams()
    const [allData, setAllData] = useState([])
    const [phoneNo,setPhoneNo] = useState()


    async function getallData() {
        setLoader(true)
        try {
            let [res] = await getCollectionData('payment')
            console.log(res,"///////////////////////////////////")
            if (res !== undefined) {
                setAllData(res)
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (activeTab === '0') {
            getallData()
        }
    }, [activeTab])
    async function getUserData() {
        setLoader(true)
        try {
            let [response] = await getCollectionData('users', "userId", '==','id')
            console.log(response.phoneNumber,"///////////////////////////////////")
            if (response !== undefined) {
                setPhoneNo(response.phoneNumber)
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (activeTab === '0') {
            getUserData()
        }
    }, [activeTab])

    const payerInfo = allData?.data?.payer?.payer_info || {}
    const transactions = allData?.data?.transactions || []
    const transactionAmount = transactions.map(e=> e.amount.total).join(', ')
    const formattedDate = moment(allData?.data?.create_time).format('YYYY-MM-DD HH:mm:ss')

    return (
        <>
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='fs-5 fw-600 text-secondary'>Payment Details</div>
                    {/* <button className='btn btn-sm btn-primary' onClick={() => setOpenAddModal(true)}>Add Document</button> */}
                </div>
                <div className={`mt-4`}>


                    <div className='border rounded p-3 mt-3'>
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Business Owner Name</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={`${payerInfo?.first_name}${" "}${payerInfo?.last_name}`}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Business Name</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={payerInfo?.business_name}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Phone No</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={phoneNo}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Email Id</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={payerInfo?.email}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">City</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={payerInfo?.shipping_address?.city}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Amount</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value= {transactionAmount}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Currency</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value= { transactions.map(e=> e.amount.currency).join(', ')}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Transaction fees</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value= { transactions?.map(e=> e.related_resources?.map(e =>e.sale?.transaction_fee?.value)).join(', ')}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Transaction Id</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData?.paymentId}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Transaction Type</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData?.data?.payer?.payment_method}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Transaction Mode</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={ transactions?.map(e=> e.related_resources?.map(e =>e.sale?.payment_mode)).join(', ')}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Merchant Id</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={ transactions?.map(e=> e.payee?.merchant_id).join(', ')}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Status</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData?.status}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Date and Time</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={formattedDate}
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentDetailsView
