export const paginateData = (page, itemPerPage, allData) => {
    if (!Array.isArray(allData)) {
        return [];
    }
    const startIndex = page * itemPerPage
    const endIndex = page * itemPerPage + itemPerPage
    return allData.slice(startIndex, endIndex);
}


export function changeDateFormate(rowData){
    return rowData?.seconds * 1000 + rowData?.nanoseconds / 1e6
}