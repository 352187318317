import React from 'react'
import { useForm } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';
import { Modal, ModalBody } from 'reactstrap';

function TransactionTypeModal({ open, setOpen, submit }) {
  const { register, handleSubmit, formState: { errors } } = useForm();
  function toggle (){
    setOpen(!open)
}
  return (
    <Modal size='sm' isOpen={open} centered >
    <ModalBody>
        <div className='d-flex justify-content-between align-items-center'>
            <div className='fw-600'>Select status</div>
            <IoClose className='fs-5 pointer' onClick={toggle}/>
        </div>
        <div className='pt-3'>
            {open?<form onSubmit={handleSubmit(submit)}>
                <div className='d-flex flex-column gap-3'>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value='Upi' id="radio1" {...register('transactionType', { required: true })} />
                        <label class="form-check-label" htmlFor="radio1">
                            Upi
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value='Credit' id="radio2" {...register('transactionType', { required: true })} />
                        <label class="form-check-label" htmlFor="radio2">
                            Credit Card
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value='paypal' id="radio2" {...register('transactionType', { required: true })} />
                        <label class="form-check-label" htmlFor="radio2">
                            Paypal
                        </label>
                    </div>
                    
                </div>
                <div className='d-flex justify-content-end'>
                    <button className='btn btn-primary' type='submit'>Filter</button>
                </div>
            </form>:''}
        </div>
    </ModalBody>
</Modal>
  )
}

export default TransactionTypeModal
