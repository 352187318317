import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IoArrowBack } from "react-icons/io5";
import { addDocument, createDocument } from '../../Firebase/CloudFirestore/SetData';
import Loader from '../../Compunents/Loader/Loader';


export default function CreateJobTitle() {
    const navigate = useNavigate()
    const [loader,setLoader] = useState(false)
    const [formData, setFormData] = useState({
        title: '',
        jobListingFee: '',
        payratesForBusiness: '',
        salariesForJobSeekers: ''
    })


    function handleChange(e) {
        let { name, value } = e.target
        if (e.target.value == ' ') {
            e.target.value = ''
        } else {
            setFormData(pre => {
                return {
                    ...pre,
                    [name]: value
                }
            })

        }
    }


    async function submitData(e) {
        e.preventDefault()
        try {
            setLoader(true)
            let payload = {
                ...formData,
                createdAt: new Date(),
                numberOfJobListing:0,
                numberOfJobSeekers:0
            }

            await addDocument('jobTitles', payload)
            navigate('/jobTitles')

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }




    return (
        <>
        <Loader open={loader}/>
            <button className='btn ps-0 d-flex align-items-center' onClick={() => navigate('/jobTitles')}><IoArrowBack className='me-1' /> Back</button>
            <h2 className='mt-4'>Create New Job Title</h2>
            <form className='w-100' onSubmit={submitData}>
                <div className='row mt-4'>
                    <div className='col-md-6'>
                        <label className='form-label mb-1'>Job Title: <span className='text-danger'>*</span></label>
                        <input type='text' required name='title' value={formData.title} onChange={handleChange} className='form-control' placeholder='Enter Job Title' />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-4 col-sm-6 col-12 mt-3 d-flex flex-column'>
                        <label className='form-label mb-1'>Job Listing Fees (JOD): <span className='text-danger'>*</span></label>
                        <input type='number' required name='jobListingFee' value={formData.jobListingFee} onChange={handleChange} className='form-control mt-auto' placeholder='Enter Amount' />
                    </div>
                    <div className='col-md-4 col-sm-6 col-12 mt-3 d-flex flex-column'>
                        <label className='form-label mb-1'>Payrates for Business / hour / person (JOD): <span className='text-danger'>*</span></label>
                        <input type='number' required name='payratesForBusiness' value={formData.payratesForBusiness} onChange={handleChange} className='form-control mt-auto' placeholder='Enter Amount' />
                    </div>
                    <div className='col-md-4 col-sm-6 col-12 mt-3 d-flex flex-column'>
                        <label className='form-label mb-1'>Salaries for Jon Seekers / hour / person (JOD): <span className='text-danger'>*</span></label>
                        <input type='number' required name='salariesForJobSeekers' value={formData.salariesForJobSeekers} onChange={handleChange} className='form-control mt-auto' placeholder='Enter Amount' />
                    </div>
                </div>
                <div className='mt-4 w-100 d-flex justify-content-end'>
                    <button className='btn btn-primary' type='submit'>Create Job</button>
                </div>
            </form>
        </>
    )
}
