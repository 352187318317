import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";


export const emailPasswordLogin = async (payload) => {
  const res = await signInWithEmailAndPassword(auth, payload.email, payload.password).catch((err) => {
    return false;
  });

  return res;
};


