import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getMatchingData } from '../../../Firebase/CloudFirestore/GetData'
import moment from 'moment'
import { changeDateFormate } from '../../../Utils/Utils'

export default function ExperienceDetails({ activeTab, setLoader }) {
    let { id } = useParams()
    const [allExperience, setAllExperience] = useState([])


    async function getProfetionalData() {
        setLoader(true)
        try {
            let [res] = await getMatchingData('professionalDetails', 'userId', '==', id)
            if (res !== undefined) {
                setAllExperience(res?.experiences)
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (activeTab === '2') {
            getProfetionalData()
        }
    }, [activeTab])
    return (
        <>
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='fs-5 fw-600 text-secondary'>Experience</div>
                    {/* <button className='btn btn-sm btn-primary' onClick={() => setOpenAddModal(true)}>Add Document</button> */}
                </div>
                <div className={` mt-4`}>
                    {allExperience?.map((res, index) => (
                        <>
                            <div key={index} className='border rounded p-3 mt-3'>
                                <div className='d-flex flex-md-row flex-sm-row flex-column justify-content-md-between justify-content-sm-between justify-content-start'>
                                    <div>
                                        <div className='fw-bold text-secondary text-capitalize'>{res?.title}</div>
                                        <div className='text-gray text-capitalize'>{res?.companyName}</div>
                                    </div>
                                    <div>
                                        <div className='f-14 text-gray'>{moment(changeDateFormate(res?.startDate))?.format('YYYY')} - {res?.currentlyWorking ? moment(changeDateFormate(res?.endDate))?.format('YYYY') : 'Present'}</div>
                                        <div className='f-14 text-gray'>{res?.totalExperience}</div>
                                    </div>
                                </div>
                                <div className='f-14 text-gray mt-2 text-capitalize'>{res?.university}</div>
                                <div className='f-14 text-gray mt-2'>{res?.grade == '' ? '' : res?.grade}</div>
                                <div className='mt-2'>
                                    <div className='text-gray fw-500'>Job Description:</div>
                                    <div className='text-gray f-13 mt-3'>{res?.description}</div>
                                </div>
                            </div>
                        </>
                    ))}

                </div>
            </div>
        </>
    )
}
