import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material'
import React from 'react'



export default function MultiSelectDropdown({ options, value, setValue, disabled }) {

    const handleChange = (event) => {
        const { target: { value }, } = event;
        setValue(
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    return (
        <>

            <Select
                className={`w-100`}
                required
                disabled={disabled !== undefined ? disabled : false}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={value}
                onChange={handleChange}
                renderValue={(selected) => selected?.join(', ')}
                sx={{
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'var(--primaryColor)',
                        border: '1px solid var(--primaryColor)'
                    }
                }}
            >
                {options?.map((name) => (
                    <MenuItem key={name} value={name}>
                        <Checkbox checked={value?.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Select>
        </>
    )
}
