import React from 'react'
import { IoClose } from "react-icons/io5";
import { useForm } from 'react-hook-form';
import { Modal, ModalBody } from 'reactstrap';

export default function DateFilterModal({ open, setOpen, submit }) {
    const { register, handleSubmit, formState: { errors } } = useForm();

    function toggle() {
        setOpen(!open)
    }

    return (
        <>
            <Modal size='sm' isOpen={open} centered >
                <ModalBody>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='fw-600'>Select date</div>
                        <IoClose className='fs-5 pointer' onClick={toggle} />
                    </div>
                    <div className='pt-3'>
                        {open?<form onSubmit={handleSubmit(submit)}>
                            <input className='form-control' type='date' {...register('date', { required: true })} />
                            <div className='d-flex justify-content-end mt-3'>
                                <button className='btn btn-primary' type='submit'>Filter</button>
                            </div>
                        </form>:''}
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
