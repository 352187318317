import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

export default function Loader({open}) {
    return (
        <>
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                className='text-white'
                open={open}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
