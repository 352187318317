import React from 'react'
import { Modal, ModalBody } from 'reactstrap'

export default function DeleteModal({ open, setOpen, itemName, deleteFunction }) {

    function toggle() {
        setOpen(!open)
    }
    
    return (
        <>
            <Modal size='md' isOpen={open} centered scrollable>
                <ModalBody>
                    <div className='p-3'>
                        <div className='fs-4 fw-bold'>Delete {itemName}</div>
                        <div className='mt-2'>Are you sure want to delete this {itemName}?</div>
                        <div className='d-flex justify-content-end gap-3 mt-4'>
                            <button className='btn btn-sm border' onClick={toggle}>Cancel</button>
                            <button className='btn btn-sm btn-primary' onClick={deleteFunction}>Delete</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
