import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { getCollectionData } from '../../Firebase/CloudFirestore/GetData'
import { changeDateFormate } from '../../Utils/Utils'

const JobDetailsView = ({ activeTab, setLoader }) => {
    let { id } = useParams()
    const [allData, setAllData] = useState([])


    async function getallData() {
        setLoader(true)
        try {
            let [res] = await getCollectionData('jobListing', 'userId', '==', id)
            console.log(res)
            if (res !== undefined) {
                setAllData(res)
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (activeTab === '0') {
            getallData()
        }
    }, [activeTab])
    return (
        <>
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='fs-5 fw-600 text-secondary'>Job Details</div>
                    {/* <button className='btn btn-sm btn-primary' onClick={() => setOpenAddModal(true)}>Add Document</button> */}
                </div>
                <div className={`mt-4`}>


                    <div className='border rounded p-3 mt-3'>
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Name</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData.jobTitle}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Job Type</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData.jobType}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Job Location</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData.jobLocation}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Description</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData.jobDescription}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Job Id</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData.jobId}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Action</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value= {allData.jobStatus === 'Approve' ? 'Approve' : 'Reject'}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Job Type</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData.jobType}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Number of Employee</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData?.noOfEmployee ?allData?.noOfEmployee :'N/A' }
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Status</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    // value={allData.payRate ? `${allData.payRate.amount} ${allData.payRate.currency} per ${allData.payRate.basis}` : 'N/A'}
                                    value={allData.status}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Qualification</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData.requiredQualification}
                                />
                            </div>

                            <div className="col-md-6 mt-3">
                                <label className="f-16">Skills</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData.requiredSkills}
                                />
                            </div>

                            <div className="col-md-6 mt-3">
                                <label className="f-16">Year Of Experience</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData.yearOfExperience}
                                />
                            </div>
                        </div>

                    </div>




                </div>
            </div>
            <div>
        </div>
            {allData.jobType === "sales" || allData.jobType === "campaign" && (
               <div className='d-flex justify-content-between align-items-center mt-3'>
                <div className='fs-5 fw-600 text-secondary'>Working Hours</div>
              
                   <div className={`mt-4`}>
                    <div className='border rounded p-3 mt-3'>
                        <div className="row">
                             <div className="col-md-6 mt-3">
                                <label className="f-16">Start Time</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData?.workingHours?.startTime}
                                />
                            </div>
				                <div className="col-md-6 mt-3">
                                <label className="f-16">End Time</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData?.workingHours?.endTime }
                                />
                            </div>	
                            <div className="col-md-12 mt-3">
                                <label className="f-16">Working Days</label>
                                <input
                                    className='form-control'
                                    disabled
                                    type="text"
                                    value={allData?.workingHours?.days}
                                />
                            </div>          
                        </div>
                    </div>
                </div>       
            </div>       
            )} 
               
       
        </>
    )
}

export default JobDetailsView
